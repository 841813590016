import React from "react"
import Hero from "../components/book-appt/hero-book-appt"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import ReferralForm from "../components/book-appt/book-appt"
import { graphql } from "gatsby"

const BookAppointmentPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
       <MetaCards data={data.contentfulBookAnAppointmentPage.webMeta}/>
      <Hero />
      <ReferralForm data={data.contentfulBookAnAppointmentPage} />
    </Layout>
  )
}

export default BookAppointmentPage

export const query = graphql`
  {
    contentfulBookAnAppointmentPage {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      referralLinks {
        id
        title
        content {
          content
        }
        url
      }
      referralSlider {
        id
        testimonial {
          testimonial
        }
        client
      }
    }
  }
`
